import axios from "services/axios"

const updatePassword = (oldPassword: string, newPassword: string) => {
	return axios.patch('/user/password', { oldPassword, newPassword }).then(response => response.data)
}

const userService = {
	updatePassword
}

export default userService
