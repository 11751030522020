import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import authService from 'services/auth-service';

export const login = createAsyncThunk(
	'auth/login',
	async (user: { email: string; password: string }, thunkAPI) => {
		try {
			const response = await authService.login(user.email, user.password)

			if (response.message === 'Login Successful!') {
				return thunkAPI.fulfillWithValue({ ...response.user, accessToken: response.accessToken })
			}
			return thunkAPI.rejectWithValue(false)
		} catch (error: any) {
			return thunkAPI.rejectWithValue(false)
		}
	}
)

const initialState = {} as AuthState

const authSlice = createSlice({
	name: 'auth',
	initialState: initialState,
	reducers: {
		logOut(state) {
			return initialState
		}
	},
	extraReducers: builder => {
		// Add reducers for additional action types here, and handle loading state as needed
		builder.addCase(login.fulfilled, (_state: AuthState, action: PayloadAction<AuthState>) => {
			console.log('AA', action.payload)

			const { id, name, email, accessToken } = action.payload
			return {
				id,
				name,
				email,
				accessToken
			}
		}),
			builder.addCase(login.rejected, (_state: AuthState, _action: PayloadAction<any>) => {
				return initialState
			})
	}
})

const { reducer } = authSlice

export const { logOut } = authSlice.actions

export default reducer
