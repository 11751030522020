import { useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { toast } from 'react-toastify'
import { useAppDispatch } from 'hooks'
import { login } from 'slices/auth'
import { Input } from 'components/input'

export const Login = () => {
	const dispatch = useAppDispatch()

	const schema = yup.object<{ email: string; password: string }>().shape({
		password: yup.string().required('Password is required'),
		email: yup.string().required('Email is required').email('Enter a valid email')
	})

	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm<{ email: string; password: string }>({
		resolver: yupResolver(schema as any),
		mode: 'all'
	})

	const onSubmit = (data: { email: string; password: string }) => {
		dispatch(login(data))
			.unwrap()
			.then(() => toast.success('Login Successful'))
			.catch(() => toast.error('Invalid Credentials'))
	}

	return (
		<div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
			<div className="sm:mx-auto sm:w-full sm:max-w-sm">
				<img
					className="mx-auto h-10 w-auto"
					src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
					alt="Your Company"
				/>
				<h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
					Sign in to your account
				</h2>
			</div>

			<div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
				<form onSubmit={handleSubmit(onSubmit)} className="space-y-6" action="#" method="POST">
					<Input
						register={register}
						errors={errors}
						name="email"
						labelText="Email address"
						type="email"
						autoComplete="email"
					/>

					<Input
						register={register}
						errors={errors}
						name="password"
						type="password"
						labelText="Password"
						autoComplete="current-password"
					/>
					<button
						type="submit"
						className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
						Sign in
					</button>
				</form>
			</div>
		</div>
	)
}
