import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'

import { ToastContainer } from 'react-toastify'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'

import 'react-toastify/dist/ReactToastify.css'
import 'tailwindcss/tailwind.css'
import store from 'store'
import { Routing } from 'routing'

const container = document.getElementById('root')
const persistor = persistStore(store)

if (container) {
	const root = createRoot(container)
	root.render(
		<StrictMode>
			<Provider store={store}>
				<PersistGate persistor={persistor}>
					<ToastContainer pauseOnFocusLoss={false} />
					<Routing />
				</PersistGate>
			</Provider>
		</StrictMode>
	)
}
