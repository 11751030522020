import axios from "services/axios"

const getAccounts = (): Promise<SubAccount[]> => {
	return axios.get('/account').then(response => response.data)
}

const deleteAccount = (accountId: number) => {
	return axios.delete(`/account/${accountId}`).then(response => response.data)
}

const accountService = {
	getAccounts,
  deleteAccount
}

export default accountService
