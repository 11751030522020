import { InputHTMLAttributes } from 'react'
import { FieldErrors, FieldValues, UseFormRegister } from 'react-hook-form'
import { cn } from 'utils/cn'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
	labelText?: string
	name: string
	index?: string
	register?: UseFormRegister<any>
	errors?: FieldErrors<FieldValues>
	className?: string
}

export const Input = ({ labelText, index, name, register, errors, ...props }: InputProps) => {
	const errorText = errors?.[name]?.message as string

	return (
		<div className="grow">
			<div className="flex flex-col gap-y-2">
				<label htmlFor={name} className="block text-sm font-medium leading-6 text-gray-900">
					{labelText}
				</label>
				<input
					{...(register?.(name) ?? {})}
					name={name}
					id={index}
					{...props}
					className={cn(
						'block w-full px-3 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6',
						props.className
					)}
				/>
			</div>
			{errorText && <p className="mt-1 text-xs text-red-500">{errorText}</p>}
		</div>
	)
}
