import axios from 'axios'
import axiosToken from "services/axios"
import { apiHost } from '../utils/host'
import { indexedStorageDB } from '../utils/local-forage'

const login = (email: string, password: string) => {
	return axios
		.post(apiHost + '/user/login', {
			email,
			password
		})
		.then(response => {
			return { ...response.data, accessToken: response.headers['x-auth-token'] }
		})
}

const initiate = () => {
	return axiosToken.get('/auth/initiate').then(response => {
		return response.data;
	})
}

const logout = () => {
	indexedStorageDB.clear()
	window.location.reload()
}

const authService = {
	login,
	initiate,
	logout
}

export default authService
