import { useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { toast } from 'react-toastify'
import userService from 'services/user-service'
import { Input } from 'components/input'
import { Navbar } from 'components/navbar'

// import userService from 'services/user-service'

export const Settings = () => {
	const schema = yup.object<{ oldPassword: string; newPassword: string }>().shape({
		oldPassword: yup.string().required('Old Password is required'),
		newPassword: yup.string().required('New Password is required')
	})

	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm<{ oldPassword: string; newPassword: string }>({
		resolver: yupResolver(schema as any),
		mode: 'all'
	})

	const onSubmit = (data: { oldPassword: string; newPassword: string }) => {
		userService
			.updatePassword(data.oldPassword, data.newPassword)
			.then(() => toast.success('Password updated successfully'))
			.catch(err => {
				if (err?.response?.data?.error) {
					return toast.error(err?.response?.data?.error)
				}
				toast.error('Error occured while updating password')
			})
	}

	return (
		<>
			<Navbar />
			<div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
				<div className="sm:mx-auto sm:w-full sm:max-w-sm">
					<h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
						Update Password
					</h2>
				</div>

				<div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
					<form onSubmit={handleSubmit(onSubmit)} className="space-y-6" action="#" method="POST">
						<Input
							register={register}
							errors={errors}
							name="oldPassword"
							labelText="Old Password"
							type="password"
							autoComplete="old-password"
						/>

						<Input
							register={register}
							errors={errors}
							name="newPassword"
							type="password"
							labelText="New Password"
							autoComplete="new-password"
						/>
						<button
							type="submit"
							className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
							Update Password
						</button>
					</form>
				</div>
			</div>
		</>
	)
}
