import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { useAppSelector } from '../hooks'
import { Login } from '../pages/login'
import { Settings } from '../pages/settings'
import { SubAccounts } from '../pages/sub-accounts'

export const Routing = () => {
	const auth = useAppSelector(state => state.auth)

	return (
		<BrowserRouter>
			<Routes>
				<Route
					path="/login"
					element={
						<PublicRoute auth={auth}>
							<Login />
						</PublicRoute>
					}
				/>
				<Route
					path="/settings"
					element={
						<ProtectedRoute auth={auth}>
							<Settings />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/"
					element={
						<ProtectedRoute auth={auth}>
							<SubAccounts />
						</ProtectedRoute>
					}
				/>
			</Routes>
		</BrowserRouter>
	)
}

type RouteProps = {
	auth?: AuthState
	redirectPath?: string
	children: JSX.Element
	customRedirect?: boolean
}

const ProtectedRoute = ({
	auth,
	children,
	customRedirect,
	redirectPath = '/login'
}: RouteProps) => {
	if (!auth?.accessToken || customRedirect) {
		return <Navigate to={redirectPath} replace={true} />
	}

	return children
}

const PublicRoute = ({ auth, children, redirectPath = '/' }: RouteProps) => {
	if (auth?.accessToken) {
		return <Navigate to={redirectPath} replace={true} />
	}

	return children
}
