import { TrashIcon } from '@heroicons/react/24/outline'
import { Modal } from 'components/modal'
import { Navbar } from 'components/navbar'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import accountService from 'services/account-service'
import authService from 'services/auth-service'
import { apiHost } from 'utils/host'

export const SubAccounts = () => {
	const [accounts, setAccounts] = useState<SubAccount[]>([])
	const [forceRefresh, setForceRefresh] = useState(false)
	const [isOpen, setIsOpen] = useState(false)
	const [selectedId, setSelectedId] = useState<number>()

	useEffect(() => {
		accountService.getAccounts().then(res => setAccounts(res))
	}, [forceRefresh])

	const handleDelete = (id: number) => {
		accountService
			.deleteAccount(id)
			.then(() => {
				toast.success('Sub account deleted successfully')
				setSelectedId(undefined)
				setIsOpen(false)
				setForceRefresh(prev => !prev)
			})
			.catch(() => toast.error('Error occured while deleting sub account'))
	}

	const initiateAuth = async () => {
		authService.initiate().then(res => {
			if (res && res.redirectUrl) {
				window.open(res.redirectUrl, '_blank')
			}
		})
	}

	return (
		<>
			<Modal
				id={selectedId as number}
				isOpen={isOpen}
				onClose={() => setIsOpen(false)}
				onDelete={handleDelete}
			/>
			<Navbar />
			<div className="px-6 my-14 sm:px-8 lg:px-12">
				<div className="sm:flex sm:items-center">
					<div className="sm:flex-auto">
						<h1 className="font-semibold leading-6 text-xl text-center text-gray-900">
							Sub Accounts
						</h1>
					</div>
					<div>
						<button
							className="text-white bg-indigo-600 hover:bg-indigo-700 px-3 py-2 rounded-md text-sm font-medium"
							onClick={initiateAuth}>
							Initiate
						</button>
					</div>
				</div>
				<div className="mt-8 flow-root">
					<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
						<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
							<table className="w-full table-auto divide-y divide-gray-300">
								<thead>
									<tr>
										<th
											scope="col"
											className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
											Id
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
											Name
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
											Access Token
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
											Refresh Token
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
											Location Id
										</th>
										<th scope="col" className="relative py-3.5 flex gap-x-2 pl-3 pr-4 sm:pr-0">
											<span className="sr-only">Action</span>
										</th>
									</tr>
								</thead>
								<tbody className="divide-y divide-gray-200">
									{accounts.map(account => (
										<tr key={account.id}>
											<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
												{account.id}
											</td>
											<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
												{account.name}
											</td>
											<td className="whitespace-nowrap max-w-32 sm:max-w-44 overflow-hidden text-ellipsis px-3 py-4 text-sm text-gray-500">
												{account.accessToken}
											</td>
											<td className="whitespace-nowrap max-w-32 sm:max-w-44 overflow-hidden text-ellipsis px-3 py-4 text-sm text-gray-500">
												{account.refreshToken}
											</td>
											<td className="whitespace-nowrap py-4 text-sm text-gray-500">
												{account.locationId}
											</td>
											<td className="relative whitespace-nowrap flex gap-x-6 py-4 text-right text-sm font-medium sm:pr-0">
												<TrashIcon
													onClick={() => {
														setSelectedId(account.id)
														setIsOpen(true)
													}}
													className="h-5 w-5 text-red-500 cursor-pointer hover:text-red-600"
												/>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
